import React, { useEffect, useState } from "react";
import { CgArrowRightO, CgArrowLeftO } from "react-icons/cg";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
import { ErrorMessage, Field, Formik } from "formik";
import * as yup from "yup";

import requests from "../../services/requests";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import Card from "../../components/Cards";
import TitleC6 from "../../components/Typography/TitleC6";
import Modal from "../../components/Modals";
import Paginate from "../../components/Paginate/Paginate";
import Container from "./styles";
import Table from "../../components/styles/Table";
import XpandContainer from "../../components/XpandContainer";
const IndexPage = () => {
    const [load, setLoad] = useState(false);
    const [list, setList] = useState([]);
    const [extraFilterInformation, setExtraFilterInformation] = useState({});
    const [action, setAction] = useState(1);
    const [modalContentTitle, setModalContentTitle] = useState("");
    const [modalContent, setModalContent] = useState("");
    const [visibleModalContent, setVisibleModalContent] = useState("");
    const [meta, setMeta] = useState({
        current_page: 1,
    });

    function handleScroll(direction) {
        const element = document.getElementById("table-responsive");
        const { scrollLeft } = element;
        document
            .getElementById("table-responsive")
            .scrollTo({ left: direction === "left" ? scrollLeft - 300 : scrollLeft + 300, behavior: "smooth" });
    }

    const listBenchmarks = () => {
        setLoad(true);
        requests
            .listBenchmarks(
                {
                    search: "",
                    filters: { ...extraFilterInformation },
                    period: {
                        startsAt: null,
                        endsAt: null,
                    },
                },
                meta.current_page > 0 ? meta.current_page : 1
            )
            .then(data => {
                setList(data.data);

                setMeta(data.meta);

                setLoad(false);
            });
    };

    useEffect(() => {
        listBenchmarks();
    }, []);

    useEffect(() => {
        listBenchmarks();
    }, [action, extraFilterInformation]);

    const actionModalContent = () => {
        setVisibleModalContent(!visibleModalContent);
    };

    return (
        <Layout>
            {/* Modal de conteúdo longo */}
            <Modal
                show={visibleModalContent}
                borderTitle={false}
                onClose={actionModalContent}
                size="w-3/4"
                title={
                    <div className="flex items-center">
                        <h5 className={`mr-6 text-roxo_oficial font-bold`}>{modalContentTitle}</h5>
                    </div>
                }
            >
                <div className="text-center mb-4 mr-4 text-center">
                    <p className="mt-2 font-normal">{modalContent}</p>
                </div>
            </Modal>
            <SEO title="Benchmark" />
            <div className="p-4">
                <Card className="mb-5">
                    <TitleC6>BENCHMARCK - CONSULTA</TitleC6>
                    <div className="border-t border-C2 border-solid pt-4 pb-12">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                originPort: "",
                                destinationPort: "",
                                consignatario: "",
                                originCountry: "",
                                hscode: "",
                                mercadoria: "",
                                cargaType: "",
                            }}
                            validationSchema={yup.object().shape({
                                originPort: yup.string(),
                                destinationPort: yup.string(),
                                consignatario: yup.string(),
                                originCountry: yup.string(),
                                hscode: yup.string(),
                                mercadoria: yup.string(),
                                cargaType: yup.string(),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);

                                setExtraFilterInformation(values);

                                setSubmitting(false);
                            }}
                        >
                            {({ handleChange, handleSubmit, isSubmitting, values }) => (
                                <form onSubmit={handleSubmit} className="items-center justify-start ">
                                    <div className="flex xs:flex-wrap lg:flex-no-wrap mb-5">
                                        <div className="xs:w-full md:w-1/2 px-1 xs:mb-5 lg:mb-0">
                                            <label className="block text-sm text-left text-C6 text" htmlFor="originPort">
                                                Porto de Origem
                                            </label>
                                            <Field
                                                type="text"
                                                className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                name="originPort"
                                                id="originPort"
                                                onChange={handleChange}
                                                value={values.originPort}
                                            />
                                            <ErrorMessage
                                                component="label"
                                                name="originPort"
                                                className="text-red font-light w-full"
                                            />
                                        </div>
                                        <div className="xs:w-full md:w-1/2 px-1 xs:mb-5 lg:mb-0">
                                            <label
                                                className="block text-sm text-left text-C6 text"
                                                htmlFor="destinationPort"
                                            >
                                                Porto de Destino
                                            </label>
                                            <Field
                                                type="text"
                                                className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                name="destinationPort"
                                                id="destinationPort"
                                                onChange={handleChange}
                                                value={values.destinationPort}
                                            />
                                            <ErrorMessage
                                                component="label"
                                                name="destinationPort"
                                                className="text-red font-light w-full"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex xs:flex-wrap lg:flex-no-wrap mb-5">
                                        <div className="xs:w-full md:w-1/2 lg:w-1/4 px-1 xs:mb-5 lg:mb-0">
                                            <label className="block text-sm text-left text-C6 text" htmlFor="consignatario">
                                                Consignatário
                                            </label>
                                            <Field
                                                type="text"
                                                className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                name="consignatario"
                                                id="consignatario"
                                                onChange={handleChange}
                                                value={values.consignatario}
                                            />
                                            <ErrorMessage
                                                component="label"
                                                name="consignatario"
                                                className="text-red font-light w-full"
                                            />
                                        </div>
                                        <div className="xs:w-full md:w-1/2 lg:w-1/4 px-1 xs:mb-5 lg:mb-0">
                                            <label className="block text-sm text-left text-C6 text " htmlFor="originCountry">
                                                País de Origem
                                            </label>
                                            <Field
                                                type="text"
                                                className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                name="originCountry"
                                                id="originCountry"
                                                onChange={handleChange}
                                                value={values.originCountry}
                                            />
                                            <ErrorMessage
                                                component="label"
                                                name="originCountry"
                                                className="text-red font-light w-full"
                                            />
                                        </div>
                                        <div className="xs:w-full md:w-1/2 lg:w-1/4 px-1 xs:mb-5 lg:mb-0">
                                            <label className="block text-sm text-left text-C6 text " htmlFor="hscode">
                                                HS Code
                                            </label>
                                            <Field
                                                type="text"
                                                className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                name="hscode"
                                                id="hscode"
                                                onChange={handleChange}
                                                value={values.hscode}
                                            />
                                            <ErrorMessage
                                                component="label"
                                                name="hscode"
                                                className="text-red font-light w-full"
                                            />
                                        </div>
                                        <div className="xs:w-full md:w-1/2 lg:w-1/4 px-1 xs:mb-5 lg:mb-0">
                                            <label className="block text-sm text-left text-C6 text " htmlFor="mercadoria">
                                                Mercadoria
                                            </label>
                                            <Field
                                                type="text"
                                                className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                name="mercadoria"
                                                id="mercadoria"
                                                onChange={handleChange}
                                                value={values.mercadoria}
                                            />
                                            <ErrorMessage
                                                component="label"
                                                name="mercadoria"
                                                className="text-red font-light w-full"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex xs:flex-wrap lg:flex-no-wrap mb-5">
                                        <div className="xs:w-full md:w-1/2 lg:w-1/4 px-1 xs:mb-5 lg:mb-0">
                                            <label className="block text-sm text-left text-C6 text " htmlFor="cargaType">
                                                Tipo de Carga
                                            </label>
                                            <Field
                                                type="text"
                                                className="w-full rounded-md border py-1 px-2 border-C2 h-10"
                                                name="cargaType"
                                                id="cargaType"
                                                onChange={handleChange}
                                                value={values.cargaType}
                                            />
                                            <ErrorMessage
                                                component="label"
                                                name="cargaType"
                                                className="text-red font-light w-full"
                                            />
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                        className="float-right bg-roxo_oficial p-3 rounded-md text-white mt-2"
                                    >
                                        Buscar {isSubmitting && <ClipLoader size="13px" color="white" />}
                                    </button>
                                </form>
                            )}
                        </Formik>
                    </div>
                </Card>
                <Card>
                    <Container>
                        <a id="btnLeft" style={{ zIndex: 48 }} onClick={() => handleScroll("left")}>
                            <CgArrowLeftO size={20} />
                        </a>
                        <a id="btnRight" style={{ zIndex: 48 }} onClick={() => handleScroll("right")}>
                            <CgArrowRightO size={20} />
                        </a>
                        <main className="overflow-auto" id="table-responsive">
                            <XpandContainer>
                                <Table id="table-responsive" className="table-auto mt-6 w-full text-left">
                                    <thead className="border-b border-roxo_oficial">
                                        <tr>
                                            <th className="text-roxo_oficial">Tipo de Carga</th>
                                            <th className="text-roxo_oficial">ETS</th>
                                            <th className="text-roxo_oficial">ETA</th>
                                            <th className="text-roxo_oficial">Pais de Origem</th>
                                            <th className="text-roxo_oficial">Porto de Origem</th>
                                            <th className="text-roxo_oficial">Porto Embarque</th>
                                            <th className="text-roxo_oficial">Porto Destino</th>
                                            <th className="text-roxo_oficial">Consignatário</th>
                                            <th className="text-roxo_oficial">Atividade</th>
                                            <th className="text-roxo_oficial">Cidade</th>
                                            <th className="text-roxo_oficial">E-mail</th>
                                            <th className="text-roxo_oficial">Notificado</th>
                                            <th className="text-roxo_oficial">Nome Exportador</th>
                                            <th className="text-roxo_oficial">HSCODE</th>
                                            <th className="text-roxo_oficial">Mercadoria</th>
                                            <th className="text-roxo_oficial">TEUS</th>
                                            <th className="text-roxo_oficial">C20</th>
                                            <th className="text-roxo_oficial">C40</th>
                                            <th className="text-roxo_oficial">Vol</th>
                                            <th className="text-roxo_oficial">Peso Bruto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load ? (
                                            <tr className="">
                                                <td colspan="20" className="text-center">
                                                    <ClipLoader size={20} loading={load} />
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                {list &&
                                                    list.map(item => {
                                                        return (
                                                            <tr>
                                                                <td Style="min-width: 200px; padding-right: 15px; vertical-align: baseline;">
                                                                    {item.loadType}
                                                                </td>
                                                                <td Style="min-width: 100px; padding-right: 15px; vertical-align: baseline;">
                                                                    {moment(item.ets).format("DD/MM/Y")}
                                                                </td>
                                                                <td Style="min-width: 100px; padding-right: 15px; vertical-align: baseline;">
                                                                    {moment(item.eta).format("DD/MM/Y")}
                                                                </td>
                                                                <td Style="min-width: 200px; padding-right: 15px; vertical-align: baseline;">
                                                                    {item.originCountryRaw}
                                                                </td>
                                                                <td Style="min-width: 200px; padding-right: 15px; vertical-align: baseline;">
                                                                    {item.originPortRaw}
                                                                </td>
                                                                <td Style="min-width: 200px; padding-right: 15px; vertical-align: baseline;">
                                                                    {item.boardPortRaw}
                                                                </td>
                                                                <td Style="min-width: 200px; padding-right: 15px; vertical-align: baseline;">
                                                                    {item.destinationPortRaw}
                                                                </td>
                                                                <td Style="min-width: 400px; padding-right: 15px; vertical-align: baseline;">
                                                                    {item.recipient}
                                                                </td>
                                                                <td Style="min-width: 400px; padding-right: 15px;">
                                                                    {item.activity?.length > 40 ? (
                                                                        <span>
                                                                            {item.activity.substring(0, 40)}
                                                                            <span
                                                                                className="cursor-pointer text-roxo_oficial font-bold"
                                                                                onClick={() => {
                                                                                    setModalContentTitle("Atividade");
                                                                                    setModalContent(item.activity);
                                                                                    setVisibleModalContent(true);
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                [...]
                                                                            </span>
                                                                        </span>
                                                                    ) : (
                                                                        item.activity
                                                                    )}
                                                                </td>
                                                                <td Style="min-width: 200px; padding-right: 15px; vertical-align: baseline;">
                                                                    {item.city}
                                                                </td>
                                                                <td Style="min-width: 200px; padding-right: 15px; vertical-align: baseline;">
                                                                    {item.email}
                                                                </td>
                                                                <td Style="min-width: 200px; padding-right: 15px; vertical-align: baseline;">
                                                                    {item.notified}
                                                                </td>
                                                                <td Style="min-width: 200px; padding-right: 15px; vertical-align: baseline;">
                                                                    {item.exporter}
                                                                </td>
                                                                <td Style="min-width: 100px; padding-right: 15px; vertical-align: baseline;">
                                                                    {item.hscode?.length > 20 ? (
                                                                        <span>
                                                                            {item.hscode.substring(0, 20)}
                                                                            <span
                                                                                className="cursor-pointer text-roxo_oficial font-bold"
                                                                                onClick={() => {
                                                                                    setModalContentTitle("HSCODE");
                                                                                    setModalContent(item.hscode);
                                                                                    setVisibleModalContent(true);
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                [...]
                                                                            </span>
                                                                        </span>
                                                                    ) : (
                                                                        item.hscode
                                                                    )}
                                                                </td>
                                                                <td Style="min-width: 400px; padding-right: 15px;">
                                                                    {item.merchandise?.length > 40 ? (
                                                                        <span>
                                                                            {item.merchandise.substring(0, 40)}
                                                                            <span
                                                                                className="cursor-pointer text-roxo_oficial font-bold"
                                                                                onClick={() => {
                                                                                    setModalContentTitle("Mercadoria");
                                                                                    setModalContent(item.merchandise);
                                                                                    setVisibleModalContent(true);
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                [...]
                                                                            </span>
                                                                        </span>
                                                                    ) : (
                                                                        item.merchandise
                                                                    )}
                                                                </td>
                                                                <td Style="min-width: 100px; padding-right: 15px; vertical-align: baseline;">
                                                                    {item.teus}
                                                                </td>
                                                                <td Style="min-width: 100px; padding-right: 15px; vertical-align: baseline;">
                                                                    {item.c20}
                                                                </td>
                                                                <td Style="min-width: 100px; padding-right: 15px; vertical-align: baseline;">
                                                                    {item.c40}
                                                                </td>
                                                                <td Style="min-width: 200px; padding-right: 15px; vertical-align: baseline;">
                                                                    {item.volume}
                                                                </td>
                                                                <td Style="min-width: 200px; padding-right: 15px; vertical-align: baseline;">
                                                                    {item.grossWeight}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </>
                                        )}
                                    </tbody>
                                </Table>
                            </XpandContainer>
                        </main>
                    </Container>
                    {meta.total_pages > 1 && (
                        <Paginate meta={meta} setMeta={setMeta} action={action} setAction={setAction} showDetails={true} />
                    )}
                </Card>
            </div>
        </Layout>
    );
};
export default IndexPage;
